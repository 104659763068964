import React from 'react'
import PropTypes from 'prop-types'
import { StyledLink, Container, Img, Copy } from './card.css'

const Card = ({ title, copy, image, link, highlight }) => (
  <StyledLink to={link}>
    <Container highlight={highlight}>
      <Img src={image.publicURL} alt={title} />
      <Copy>
        <h2>{title}</h2>
        <p>{copy}</p>
        <span>Learn More</span>
      </Copy>
    </Container>
  </StyledLink>
)

Card.propTypes = {
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
}

export default Card
