import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Video from 'components/video'
import Button from 'components/button'
import { Section, Container } from './watchAndLearn.css'

const WatchAndLearn = ({ watch }) => (
  <Section>
    <Container>
      <Fade bottom delay={150} duration={1000} distance="30px">
        <Video videoSrcURL={watch.videoSrcURL} videoTitle={watch.videoTitle} />
      </Fade>
    </Container>
    <Container>
      <Fade bottom delay={150} duration={1000} distance="30px">
        <h3>{watch.heading}</h3>
        {watch.copy.map((item, i) => (
          <p key={i}>{item}</p>
        ))}
        <Button to="/business-it-contact">Contact us</Button>
      </Fade>
    </Container>
  </Section>
)

WatchAndLearn.propTypes = {
  watch: PropTypes.object.isRequired
}

export default WatchAndLearn
