import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Section = styled.section`
  // background-image: var(--greyGrad);
  color: var(--white);
  text-align: center;
  margin-bottom: var(--xl);
  position: relative;

  ${media.desktop`
    margin-bottom: var(--xxl);
  `}
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1128px;
  margin: 0 auto;
  padding: var(--lg) var(--md) var(--sm);
  z-index: 2;
  position: relative;

  ${media.tablet`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: var(--lg) var(--md) 0;
  `}
`
export const ImgContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  > div {
    height: 100%;
  }
`
