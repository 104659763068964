import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Hero from 'components/hero'
import Intro from 'components/intro'
import Features from 'components/features'
import Usps from 'components/usps'
import WatchAndLearn from 'components/watchAndLearn'
import PartnerCarousel from 'components/partnerCarousel'
import Awards from 'components/awards'
import LatestNews from 'components/latestNews'
import { graphql } from 'gatsby'

const IndexPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.homeJson.title}</title>
      <meta name="description" content={data.homeJson.description} />
    </Helmet>
    <Hero hero={data.homeJson.hero} />
    <Intro small intro={data.homeJson.intro} />
    <Features features={data.homeJson.features} />
    <Usps usps={data.homeJson.usps} />
    <WatchAndLearn watch={data.homeJson.watch} />
    <PartnerCarousel bgTint borderTop={false} />
    <Awards borderTop={false} topMargin={true} />
    <LatestNews />
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default IndexPage

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      description
      hero {
        heading
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        link
        cta
        bullets {
          copy
        }
      }
      intro {
        heading
        copy
      }
      features {
        title
        copy
        link
        image {
          publicURL
        }
        highlight
      }
      usps {
        counter
        unit
        description
      }
      watch {
        videoSrcURL
        videoTitle
        heading
        copy
      }
    }
  }
`
