import styled from 'styled-components'
import media from 'constants/breakpoints'
import bullet from 'images/tick-orange.svg'

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #333;
  min-height: calc(80vh - 108px);
  margin-bottom: var(--lg);
  position: relative;

  ${media.desktop`
    margin-bottom: var(--xxl);
  `}
`
export const Container = styled.div`
  width: 100%;
  max-width: 1128px;
  padding: var(--lg) var(--md);
  color: var(--white);
  z-index: 2;

  h1 {
    font-size: var(--titleLevel1);
    margin-bottom: var(--md);
    line-height: 1.4;

    ${media.tablet`
      font-size: var(--titleLevel3);
    `}
  }

  ul {
    margin-bottom: var(--md);
    font-family: var(--headings);

    ${media.tablet`
      column-count: 2;
      column-gap: 0;
      font-size: var(--copyMedium);
      width: 84%;
    `}

    li {
      margin-bottom: var(--sm);

      ::before {
        display: inline-block;
        content: url(${bullet});
        width: 16px;
        height: 12px;
        margin-right: 12px;
      }

      h2 {
        display: inline;
      }
    }
  }
`
export const ImgContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;

  ::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-image: var(--turquoiseGrad);
  }

  > div {
    height: 100%;
  }
`
