import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Button from 'components/button'
import { Section, Container, ImgContainer } from './hero.css'

const Hero = ({ hero }) => {
  const image = getImage(hero.image)
  return (
    <Section>
      <Container>
        <Fade bottom delay={100} duration={1000} distance="20px">
          <h1>{hero.heading}</h1>
        </Fade>
        <Fade bottom delay={250} duration={1000} distance="20px">
          <ul>
            {hero.bullets.map((bullet, i) => (
              <li key={i}>
                <h2>{bullet.copy}</h2>
              </li>
            ))}
          </ul>
        </Fade>
        <Fade bottom delay={400} duration={1000} distance="20px">
          <Button to={hero.link}>{hero.cta}</Button>
        </Fade>
      </Container>

      <ImgContainer>
        <GatsbyImage image={image} alt={hero.heading} />
      </ImgContainer>
    </Section>
  )
}
Hero.propTypes = {
  hero: PropTypes.object.isRequired
}

export default Hero
