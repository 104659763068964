import React from 'react'
import PropTypes from 'prop-types'
import Counter from './counter'
import { StaticImage } from 'gatsby-plugin-image'
import { Section, Container, ImgContainer } from './usps.css'

const Usps = ({ usps }) => (
  <Section>
    <Container>
      {usps.map((usp, i) => (
        <Counter {...usp} key={i} />
      ))}
    </Container>

    <ImgContainer>
      <StaticImage src="../../images/usp-bg.png" alt="" layout="fullWidth" placeholder="blurred" quality="100" />
    </ImgContainer>
  </Section>
)

Usps.propTypes = {
  usps: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default Usps
