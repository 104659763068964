import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import CopyBlock from 'components/copyBlock'
import Card from './card'
import { Heading, StyledDiv } from './features.css'

const Features = ({ features, withHeading, heading, bgTint, topMargin }) => (
  <CopyBlock bgTint={bgTint} topMargin={topMargin}>
    {withHeading ? (
      <Fade bottom delay={150} duration={1250} distance="30px">
        <Heading>{heading}</Heading>
      </Fade>
    ) : null}
    <StyledDiv>
      {features.map((feature, i) => (
        <Fade bottom key={i} delay={i * 100} duration={1000} distance="30px">
          <Card {...feature} />
        </Fade>
      ))}
    </StyledDiv>
  </CopyBlock>
)

Features.propTypes = {
  features: PropTypes.arrayOf(PropTypes.object).isRequired,
  withHeading: PropTypes.bool,
  heading: PropTypes.string,
  bgTint: PropTypes.bool,
  topMargin: PropTypes.bool
}

export default Features
