import React from 'react'
import PropTypes from 'prop-types'
import { Container, Iframe } from './video.css'

const Video = ({ videoSrcURL, videoTitle, ...props }) => (
  <Container>
    <Iframe
      src={videoSrcURL}
      title={videoTitle}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </Container>
)

Video.propTypes = {
  videoSrcURL: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
}

export default Video
