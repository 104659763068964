import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import CountUp from 'react-countup';
import { Container, Count, Description } from './counter.css'

const Counter = ({ counter, unit, description }) => {
  const [ref, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  })
  const endCount = Number(counter)

  return (
    <Container ref={ref}>
      <CountUp
        start={0}
        end={inView ? endCount : 0}
        suffix={unit}
        duration={3}
      >
        {({ countUpRef }) => (
          <>
            <Count ref={countUpRef} />
            <Description>{description}</Description>
          </>
        )}
      </CountUp>
    </Container>
  )
}

Counter.propTypes = {
  counter: PropTypes.string.isRequired,
  unit: PropTypes.string,
  description: PropTypes.string.isRequired,
}

export default Counter
