import styled from 'styled-components'
// import media from 'constants/breakpoints'

export const Container = styled.div`
  margin-bottom: var(--lg);
`

export const Count = styled.span`
  font-family: var(--headings);
  font-size: var(--titleLevel2);
  display: block;
  line-height: 1.8;
`

export const Description = styled.span`


`
