import styled from 'styled-components'
import media from 'constants/breakpoints'
import { default as Link } from 'components/pageTransitionLink'

export const StyledLink = styled(Link)`
  transition: box-shadow 0.25s linear;
  height: 100%;

  :hover {
    text-decoration: none;
    box-shadow: 1px 2px 22px 0 rgba(0, 0, 0, 0.3);
  }
`
export const Container = styled.div`
  background-image: ${({ highlight }) =>
    (highlight === 'purple' && 'var(--purpleGrad)') ||
    (highlight === 'green' && 'var(--greenGrad)') ||
    (highlight === 'blue' && 'var(--blueGrad)') ||
    (highlight === 'orange' && 'var(--orangeGrad)') ||
    'var(--orangeGrad)'
  };
  color: var(--white);
  padding: var(--md) var(--md);
  height: 100%;

  ${media.desktop`
    display: flex;
    flex-direction: row;
    padding: var(--lg);
  `}
`
export const Img = styled.img`
  margin-bottom: var(--md);
  width: 64px;
  height: 64px;

  ${media.desktop`
    margin-right: var(--lg);
  `}
`
export const Copy = styled.div`
  h2 {
    font-size: var(--copyMedium);
    margin-bottom: var(--xs);
  }
  p {
    margin-bottom: var(--sm);
  }
  span {
    display: block;
    font-size: var(--copyBase);
    font-family: var(--headings);
    text-decoration: underline;
    margin-bottom: var(--sm);

    ${media.desktop`
      margin-bottom: 0;
    `}
  }
`
