import styled from 'styled-components'
import media from 'constants/breakpoints'

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
  `}

  ${media.desktop`
    margin-bottom: var(--xl);
  `}

  > div {
    margin-bottom: var(--md);

    ${media.tablet`
      flex: 0 1 calc(50% - 12px);

      &:nth-child(3), &:nth-child(4) {
        margin-bottom: 0;
      }
    `}
  }
`
export const Heading = styled.h3`
  text-align: center;
  font-size: var(--titleLevel1);
  margin: 0 0 var(--lg);
`
