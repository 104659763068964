import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 1128px;
  margin: 0 auto var(--lg);
  padding: 0 var(--md);

  ${media.tablet`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  `}

  ${media.desktop`
    margin-bottom: var(--xl);
  `}
`
export const Container = styled.div`
  ${media.tablet`
    flex: 0 1 calc(50% - var(--md));
  `}

  h3 {
    font-size: var(--copyNormal);
    font-family: var(--headings);
    margin-bottom: var(--xs);
    text-transform: uppercase;
    letter-spacing: 1.4px;
  }
  p {
    margin-bottom: var(--md);
  }
`
